import DeliveryNote from '@/components/dialogs/delivery-note'
export default {
  components: {
    DeliveryNote
  },
  methods: {
    onInfoClose() {
      this.$emit('componentInfo', { 
        status: true,
        component: 'billing-list'
      })
    }
  }
}